import React from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'

import IndexPage from './pages/indexPage'

import LoginPage from './pages/login'
import CabinetPage from './pages/cabinet'
import RegPage from './pages/registration'
import ErrorPage from './pages/ErrorPage'
import ConfirmationPage from './pages/confirmation'
import Header from './components/blocks/Header'
import Footer from './components/blocks/Footer'
import {UserContextProvider} from './context/UserContext'

import {ProfileToken} from './helpers/token'

import './App.css'
import 'normalize.css'

function App({history}) {
  const PrivateRoute = ({component: Component, ...rest}) => {
    return (
      <Route
        exact
        {...rest}
        render={props =>
          checkLogin() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: {from: props.location},
              }}
            />
          )
        }
      />
    )
  }

  const checkLogin = () => {
    return ProfileToken.get()
  }

  return (
    <div className="App">
      <UserContextProvider>
        <Header history={history} />
        <Switch>
          <Route exact path="/" component={IndexPage} />
          <Route exact path="/confirmation" component={ConfirmationPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/registration" component={RegPage} />
          {/* <PrivateRoute path="/" component={CabinetPage} /> */}
          <PrivateRoute path="/cabinet" component={CabinetPage} />
          <Redirect from="/" to="/cabinet" />
          <Route path="/404" component={ErrorPage} />
          <Redirect from="*" to="/404" />
        </Switch>
        <Footer history={history} />
      </UserContextProvider>
    </div>
  )
}

export default withRouter(App)
