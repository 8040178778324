import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import CustomInput from '../../../ui/CustomInput'
import CustomButton from '../../../ui/CustomButton'
import Preloader from '../../../ui/Preloader'

import './../style.scss'

const ContactForm = ({formMethod}) => {
  const {t} = useTranslation()

  const {register, handleSubmit, errors} = useForm()
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [isSended, setSended] = useState(false)

  const onSubmit = data => {
    setError(null)
    setLoading(true)
    formMethod(data)
      .then(r => {
        setError(null)
        setLoading(false)
        setSended(true)
      })
      .catch(e => {
        setLoading(false)
        isSended(false)
        e.response && e.response.data.non_field_errors.length > 0
          ? setError(e.response.data.non_field_errors[0])
          : setError(e.response.data.message)
      })
  }

  return (
    <form className="form max" onSubmit={handleSubmit(onSubmit)}>
      {!isSended ? (
        <>
          <div className={`form__wrapper ${isLoading ? 'disabling' : ''}`}>
            <div className="form__title">Обратная связь</div>
            <div className="form__row">
              <div className="form__input">
                <CustomInput
                  inputName="firstName"
                  inputRef={register({required: true, minLength: 4, maxLength: 20})}
                  isError={errors.firstName}
                  inputPlaceholder={t('forms.firstName')}
                />
                {errors.firstName && errors.firstName.type === 'required' && (
                  <span className="form__error-label">This field is required</span>
                )}
              </div>
              <div className="form__input">
                <CustomInput
                  inputName="lastName"
                  inputRef={register({required: true, minLength: 4, maxLength: 20})}
                  isError={errors.lastName}
                  inputPlaceholder={t('forms.lastName')}
                />
                {errors.lastName && errors.lastName.type === 'required' && (
                  <span className="form__error-label">This field is required</span>
                )}
              </div>
            </div>
            <div className="form__row">
              <div className="form__input">
                <CustomInput
                  inputName="email"
                  inputRef={register({
                    required: true,
                    pattern:
                      //eslint-disable-next-line
                      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                    minLength: 5,
                    maxLength: 30,
                  })}
                  isError={errors.email}
                  inputPlaceholder={t('forms.email')}
                />
                {errors.email && <span className="form__error-label">Please insert a valid email</span>}
              </div>
              <div className="form__input">
                <CustomInput
                  inputName="phone"
                  inputRef={register({required: true, minLength: 4, maxLength: 20})}
                  isError={errors.phone}
                  inputPlaceholder={t('forms.phone')}
                />
                {errors.phone && errors.phone.type === 'required' && (
                  <span className="form__error-label">This field is required</span>
                )}
              </div>
            </div>
            <div className="form__input">
              <CustomInput
                inputType="textarea"
                inputName="message"
                inputRef={register({required: true, minLength: 4, maxLength: 20})}
                isError={errors.message}
                inputPlaceholder={t('forms.message')}
              />
              {errors.message && errors.message.type === 'required' && (
                <span className="form__error-label">This field is required</span>
              )}
            </div>
            <div className="form__btn">
              <CustomButton type="submit" className="big" text={t('forms.send')} color="blue" />
            </div>
            {error && (
              <span className="form__send-error">{error.isArray ? error.map(e => <p>{e.message}</p>) : error}</span>
            )}
            {isLoading && <Preloader transparent={true} show />}
          </div>
        </>
      ) : (
        <p className="form__send-message">Сообщение отправлено</p>
      )}
    </form>
  )
}

export default ContactForm
