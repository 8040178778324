export const ProfileToken = {
  get: function() {
    return localStorage.getItem('profile')
  },
  set: function(token) {
    localStorage.setItem('profile', token)
  },
  remove: function() {
    if (ProfileToken.get()) {
      localStorage.removeItem('profile')
    }
  },
}

export const ProfileRefreshToken = {
  get: function() {
    return localStorage.getItem('refresh')
  },
  set: function(token) {
    localStorage.setItem('refresh', token)
  },
  remove: function() {
    if (ProfileToken.get()) {
      localStorage.removeItem('refresh')
    }
  },
}
