import React from 'react'

import Agent from '../Agent'
import {useTranslation} from 'react-i18next'

import './style.scss'

const AgentsList = ({agents, openSettings, agentMethods}) => {
  const {t} = useTranslation()

  const list =
    agents &&
    agents.map((agent, i) => {
      return (
        <div key={i} className={`agents-list__item ${true ? 'active' : ''}`}>
          <Agent agent={agent} onClickSettings={openSettings} agentMethods={agentMethods} />
        </div>
      )
    })

  return (
    <div className="agents-list">
      <>
        <div className="agents-list__title">{t('agents.title')}:</div>
        {list}
      </>
    </div>
  )
}

export default AgentsList
