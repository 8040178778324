import React, {useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import Button from '../../../ui/CustomButton'

import './style.scss'

const Settings = ({agent, saveMethod, close}) => {
  const {t} = useTranslation()

  const [selectedInterface, setInterface] = useState(t('agent.interfaces'))
  const [params, setParams] = useState({center_freq: '', delta_freq: ''})
  const [isRadio, setRadio] = useState(false)
  const {handleSubmit} = useForm()

  useEffect(() => {
    if (agent) {
      if (agent.service_class === 1) setRadio(true)
      setInterface(agent.active_interface)
      setParams({center_freq: agent.params.center_freq, delta_freq: agent.params.delta_freq})
    }
    return () => {
      setInterface(t('agent.interfaces'))
      setParams({center_freq: '', delta_freq: ''})
      setRadio(false)
    }
  }, [agent, t])

  const onSubmit = () => {
    let data = {active_interface: selectedInterface}
    if (isRadio) {
      data['params'] = params
    }
    saveMethod(data)
  }

  return (
    <div className="settings-popup">
      <div className="settings-popup__wrapper">
        <div className="settings-popup__close" onClick={close}></div>

        <div className="settings-popup__title">{t('agent.settings')}</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isRadio && (
            <>
              <div className="settings-popup__interface">{t('agent.center')}</div>
              <input
                defaultValue={params.center_freq}
                name="center_freq"
                onChange={e => {
                  let value = e.target.value
                  setParams(prevState => ({
                    ...prevState,
                    center_freq: value,
                  }))
                }}
              />
              <div className="settings-popup__interface">{t('agent.band')}</div>
              <input
                defaultValue={params.delta_freq}
                name="delta_freq"
                onChange={e => {
                  let value = e.target.value
                  setParams(prevState => ({
                    ...prevState,
                    delta_freq: value,
                  }))
                }}
              />
            </>
          )}
          <div className="settings-popup__interface">
            {t('agent.current')}: {agent.active_iface}
          </div>

          <select
            className="settings-popup__select"
            name="active_interface"
            id="active_interface"
            value={selectedInterface}
            onChange={e => setInterface(e.target.value)}>
            <option disabled>{t('agent.interfaces')}</option>

            {agent &&
              agent.available_interfaces &&
              agent.available_interfaces.map((interf, i) => (
                <option key={i} value={interf}>
                  {interf}
                </option>
              ))}
          </select>
          <div className="settings-popup__button">
            <Button
              text={t('agent.save')}
              color="green"
              // disabling={agent.active_interface === selectedInterface }
              type="submit"
              className="big"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default Settings
