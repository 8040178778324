import React from 'react'

import Extender from '../../components/ui/Extender'
import ContactForm from '../../components/blocks/forms/ContactForm'
import './style.scss'
import Contacts from '../../components/blocks/index/Contacts/Contacts'
import Map from '../../components/blocks/index/Map/Map'
import {sendContactForm} from '../../services/user'

const IndexPage = ({history}) => {
  const submitForm = data => sendContactForm(data).then(response => {})

  return (
    <div className="index-page">
      <div className="index-page__wrapper">
        <div className="index-page__top">
          <h2>CVA NGIDS NeuroFortress</h2>
        </div>
        <section className="index-page__section">
          <div className="index-page__section-wrapper">
            <div className="index-page__section-row">
              <div className="index-page__section-col">
                <img width="300px" src={require('../../images/hack_red.jpg')} alt="" />
              </div>
              <div className="index-page__section-col">
                <div className="index-page__title">О Вашей кибер-безопасности</div>
                <p className="index-page__desc">
                  Вы уже столкнулись с киберпреступлением против Вас или Вашей компании? Статистика по воровству
                  кредитных карт, персональной информации, интеллектуальной собственности и другой ценной информации
                  растет в геометрической прогрессии вместе с развитием цифровых технологий! Частные лица и компании все
                  чаще становятся жертвами кибер-мошенников и шантажистов. Каждый раз, когда вы читаете об этом в
                  новостях вы думаете – что со Вами этого не произойдет…
                </p>
                <Extender>
                  <p className="index-page__desc">
                    Подобное мы наблюдаем каждый день. Мы одержимо изучаем тактику злоумышленников с 1999 года и знаем
                    отличительные черты даже самых скрытных приемов атаки. Вы не одиноки! Вы можете использовать наш
                    опыт и передовые технологии искусственного интеллекта, чтобы наблюдать и контролировать реальную
                    картину злонамеренного поведения кибер-преступников против Вас и Вашей компании. Технологии и опыт
                    которые позволяют видеть экспоненциально больше, чем это возможно достичь самостоятельно
                    стандартными средствами.
                  </p>
                  <p className="index-page__desc">
                    Теперь и Вы имеете такую возможность!
                    <ul>
                      <li>Идентификация вторжений и предотвращение</li>
                      <li>Параллельный анализ трафика различных информационных сред</li>
                      <li>Высокая скорость реакции</li>
                      <li>
                        Возможность для нейронной сети полностью использовать потенциал «самообучения» и выходить за
                        пределы базы знаний экспертной системы
                      </li>
                      <li>Отсутствие потребности регулярного обновления базы знаний</li>
                      <li>Контроль беспроводных сетей IoT и сред умных устройств (интернет-вещей)</li>
                      <li>Поддержка сертифицированных в РФ сред для интеграции (например, в рамках «ГосСОПКА»)</li>
                    </ul>
                  </p>
                  <p className="index-page__desc">
                    NGIDS NeuroFortress анализирует только параметры траффика ваших сред, не нарушая конфиденциальность
                    информации, он применяет нашу специально разработанную нейронную сеть нового поколения, чтобы
                    предупредить Вас о подозрительной активности, требующей внимания и оперативных действий. Таким
                    образом расследование становится простым и совместным, чтобы вы быстро пришли к заключению. А когда
                    инцидент требует реагирования, вы можете сделать это в любой момент, чтобы локализовать инциденты с
                    минимальными усилиями и максимальной скоростью.
                  </p>
                </Extender>
              </div>
            </div>
          </div>
        </section>
        <section className="index-page__section">
          <div className="index-page__section-wrapper">
            <div className="index-page__section-row">
              <div className="index-page__section-col">
                <img width="300px" src={require('../../images/red_lock.jpg')} alt="" />
              </div>
              <div className="index-page__section-col">
                <div className="index-page__title">Вы уже используете какое-то решение? Проверьте его..!</div>
                <p className="index-page__desc">
                  На рынке кибербезопасности представлено множество различных продуктов, но стоит обратить внимание на
                  следующие недостатки:
                </p>
                <p className="index-page__desc">
                  <ul>
                    <li>
                      Стандартные средства обеспечения кибербезопасности не фиксируют атаки с неизвестной или
                      нестабильной сигнатурой структурой (неизвестные атаки)
                    </li>
                    <li>
                      Отсутствие интегральных систем безопасности включающих беспроводные сети и среды умных устройств
                      IoT (интернет-вещей)
                    </li>
                    <li>
                      Существующие реализации эвристических механизмов обнаружения имеют большое количество ложных
                      срабатываний
                    </li>
                    <li>Недостаточная скорость работы механизмов обнаружения атак, задержки большого трафика</li>
                    <li>Идентификационные базы сигнатурных методов требуют обязательного обновления.</li>
                  </ul>
                </p>
                <p className="index-page__desc">
                  Все эти недостатки делают стандартные и популярные средства безопасности не только бесполезными но и
                  опасными, так как у пользователя возникает ложное представление о его защищенности.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="index-page__section">
          <div className="index-page__section-wrapper">
            <div className="index-page__section-row">
              <div className="index-page__section-col">
                <img width="300px" src={require('../../images/ai_man.jpg')} alt="" />
              </div>
              <div className="index-page__section-col">
                <div className="index-page__title">Наше решение - NGIDS NeuroFortress, современная кибер-защита!</div>
                <div className="index-page__desc">
                  Нашей командой был разработан - аппаратно-программный комплекс NGIDS NeuroFortress, обнаружения и
                  предотвращения угроз безопасности на всех этапах получения, передачи и обработки информации, включая
                  локальную, сетевую, беспроводную среды, а также сети умных устройств и интернета вещей IoT, на основе
                  эффективных алгоритмов машинного обучения. NGIDS NeuroFortress сочетает в себе аналитику безопасности
                  с дополнительными передовыми инструментами, которые ранее были доступны только специалистам. Он
                  обнаруживает угрозы, которые сложно или невозможно обнаружить стандартными средствами. NeuroFortress —
                  это не просто новое поколение IDS, это эволюция!
                </div>
                <Extender>
                  <p className="index-page__desc">
                    Наше передовое решение предоставляет:
                    <li>Обнаружение сложных угроз</li>
                  </p>
                  <p className="index-page__desc">
                    Реагирование на сложные постоянные угрозы (APT) требует значительных ресурсов и времени.
                    Злоумышленники действуют достаточно осторожно, чтобы избежать срабатывания сигнализации и
                    обнаружения вторжения.
                    <li>Обнаружение инсайдерских угроз</li>
                  </p>
                  <p className="index-page__desc">
                    Большинство кибератак исходят от внутренних угроз, но многие организации не борются с внутренними
                    угрозами. Независимо от того, исходят ли они от злонамеренного или неосторожного сотрудника,
                    партнера или подрядчика, такие риски трудно обнаружить и часто остаются незамеченными в течение
                    месяцев или лет.
                    <li>Защита облака</li>
                  </p>
                  <p className="index-page__desc">
                    Большинство корпоративных рабочих сред теперь находится в облаке. И хотя гибридная ИТ-среда стала
                    нормой, большинству организаций не хватает всестороннего представления о рисках и угрозах. Без такой
                    видимости невозможна эффективная защита от угроз и корректная конфигурация сетевой инфраструктуры.
                    <li>Защита важных данных</li>
                  </p>
                  <p className="index-page__desc">
                    Защита критически важных данных лежит в основе протокола безопасности. Это многогранная задача, в
                    которой используется многосторонний подход с применением исскуственного интеллекта.
                    <li>Ответ на обнаружение атаки</li>
                  </p>
                  <p className="index-page__desc">
                    Оповещения — это основа передовых методов обеспечения безопасности, позволяющих остановить вторжение
                    до того, как будет нанесен ущерб. Общая цель - быстрее реагировать, лучше координировать и разумнее
                    детектировать в рамках всей организации за счет обмена информацией.
                    <li>Приоритезация и управление рисками</li>
                  </p>
                  <p className="index-page__desc">
                    Применение политик риска к уязвимостям предупреждает администраторов о подверженности высокому
                    риску. После обнаружения уязвимостей в сетевых устройствах контекст приложений или программного
                    обеспечения становится центральным для понимания того, что требует немедленного внимания.
                    <li>Подтверждение нарушения</li>
                  </p>
                  <p className="index-page__desc">
                    NeuroFortress интегрируется с популярными решениями, чтобы обеспечить упреждающее, быстрое и
                    интеллектуальное реагирование на нарушения конфиденциальности данных с полным соблюдением
                    требований. Наш модуль конфиденциальности управляется искусственным интеллектом формирующий
                    отчетности о нарушениях конфиденциальности по всему миру, чтобы помочь организациям выполнить
                    требования о раскрытии информации о нарушениях в сроки, установленные законом.
                  </p>
                </Extender>
              </div>
            </div>
            <div className="index-page__section-row">
              <div className="index-page__section-col">
                <div className="index-page__title">NeuroFortress охраняет сети IoT</div>
                <p className="index-page__desc">
                  NF IoT - это интегрированная программа услуг и технологий, разработанная, чтобы помочь вашей
                  организации на протяжении всего пути управления угрозами. Наше решение NF IoT помогает реализовать
                  структуру NIST для доменов OT, IoT и IoMT, чтобы обеспечить прозрачность неуправляемых и подключенных
                  устройств.
                </p>
                <Extender>
                  <p className="index-page__desc">
                    Обнаружение угроз с использованием запатентованного искусственного интеллекта, машинного обучения и
                    автоматизации с помощью платформы NF IoT, обеспечивающей непрерывный мониторинг и обнаружение атак
                    или подозрительной активности.
                  </p>
                  <p className="index-page__desc">
                    Мы используем технологию, которая обнаруживает потенциальные угрозы в вашей среде - управляемые и
                    неуправляемые устройства как в вашей сети, так и за ее пределами, а также в вашем воздушном
                    пространстве. NF IoT объединяет возможности атакующих служб безопасности, управляемых служб
                    безопасности, искусственного интеллекта, реагирования на инциденты и непрерывного совершенствования.
                    NF IoT предлагает интегрированное управление жизненным циклом угроз и инцидентов.
                  </p>
                  <p className="index-page__desc">
                    Обнаружение, расследование и реагирование на расширенные атаки на основе ИИ - Расширенная аналитика
                    сокращает количество бессмысленных предупреждений и обнаруживает угрозы, которые пропускают ваши
                    текущие инструменты.
                  </p>
                </Extender>
              </div>
            </div>
          </div>
        </section>
        <section className="index-page__section">
          <div className="index-page__section-wrapper">
            <div className="index-page__section-row">
              <div className="index-page__section-col">
                <img width="300px" src={require('../../images/corp_build.jpg')} alt="" />
              </div>
              <div className="index-page__section-col">
                <div className="index-page__title">
                  Для кого предназначен NGIDS NeuroFortress, почему это необходимо?
                </div>
                <p className="index-page__desc">
                  Частные лица и Компании, владеющие важными данными: объектами авторского права, персональных данных,
                  стратегических сведений, интеллектуальной собственностью, выполняющие требования Регуляторов;
                  организации, использующие беспроводные среды; малый и средний бизнес, ИТ-компании, госкомпании,
                  энергетика, СМИ, банки, платежные системы, частные пользователи…
                </p>
                <Extender>
                  <p className="index-page__desc">
                    Вашей организации необходимо защитить критически важные активы и управлять полным жизненным циклом
                    угроз, с которыми сталкивается ваша команда. Интеллектуальный, интегрированный унифицированный
                    подход к управлению угрозами может помочь Вам обнаруживать сложные угрозы, быстро и точно
                    реагировать и восстанавливаться после сбоев. Слишком часто нескоординированный набор инструментов
                    управления угрозами, созданный с течением времени, не может предоставить всеобъемлющее
                    представление, обеспечивающее безопасные операции.
                  </p>
                  <p className="index-page__desc">
                    Безопасность беспроводных и сетей Интернета вещей IoT <br />
                    Защитите свое предприятие от угроз со стороны беспроводных, неуправляемых устройств и устройств
                    Интернета вещей. Служба безагентного управления угрозами быстро обнаруживает эти устройства с
                    помощью искусственного интеллекта и своевременно уведомляет о подозрительной активности и аномалиях.
                  </p>
                </Extender>
              </div>
            </div>
            <div className="index-page__section-row">
              <div className="index-page__section-col">
                <div className="index-page__title">Почему это необходимо и актуально для бизнеса любой отрасли?</div>
                <p className="index-page__desc">
                  Злоумышленники посылают пакеты атакующего трафика для того, чтобы выманить деньги или заблокировать
                  работу сайта конкурента. Будь то авторский блог или интернет-магазин – под атакой может оказаться
                  любой бизнес, особенно крупные компании и корпорации.
                </p>

                <p className="index-page__desc">
                  Вы в курсе всех угроз, атак и подозрительной активности Если число запросов на ваш сайт резко
                  возрастет, вы получите оповещение или сможете увидеть отчет об атаке в личном кабинете.
                </p>
                <p className="index-page__desc">
                  Что получаете? Существенное снижение рисков вредоносного воздействия на информационную систему на
                  прямую снижает затраты организационных, операционных, человеческих ресурсов компании.
                </p>
                <Extender>
                  <p className="index-page__desc">
                    Почему защита именно от NGIDS NeuroFortress?
                    <br />
                    Каждый день мы делаем вашу инфраструктуру еще безопаснее и надежнее:
                    <ul>
                      <li>Практично - Нет необходимости в дополнительном оборудовании или ПО</li>
                      <li>Функционально - Широкий выбор опций для встраивания в Вашу инфраструктуру</li>
                      <li>Быстро - Простое подключение за несколько минут</li>
                      <li>Персонально - Онлайн-аналитика трафика в личном кабинете</li>
                      <li>Экономно - Снижение издержек на аренду и обслуживание используемой инфраструктуры</li>
                      <li>Универсально - Полная совместимость с разными решениями на любых платформах.</li>
                    </ul>
                  </p>
                  <p className="index-page__desc">
                    Конкурентными преимуществами являются:
                    <ul>
                      <li>Как идентификация вторжений, так и предотвращение</li>
                      <li>Параллельный анализ трафика различных информационных сред</li>
                      <li>Высокая скорость реакции</li>
                      <li>
                        Возможность для нейронной сети полностью использовать потенциал «самообучения» и выходить за
                        пределы базы знаний экспертной системы
                      </li>
                      <li>Отсутствие потребности регулярного обновления базы знаний</li>
                      <li>Контроль беспроводных сетей и сред умных устройств (интернет-вещей)</li>
                      <li>
                        Планируется поддержка сертифицированных в РФ сред для интеграции (например в рамках «ГосСОПКА»).
                      </li>
                    </ul>
                  </p>
                </Extender>
              </div>
            </div>
          </div>
        </section>
        <section className="index-page__section">
          <div className="index-page__section-wrapper">
            <div className="index-page__section-row">
              <div className="index-page__section-col">
                <img width="300px" src={require('../../images/universe_cluster.jpg')} alt="" />
              </div>
              <div className="index-page__section-col">
                <div className="index-page__title">Как это работает?</div>
                <p className="index-page__desc">
                  NGIDS NeuroFortress – это аппаратно-программный комплекс обнаружения и предотвращения угроз
                  безопасности сетевой инфраструктуры, включая беспроводные среды интернета вещей IoT и умных устройств.
                </p>
                <p className="index-page__desc">
                  Технология обнаружения и предотвращения киберугроз – сердце комплекса – набор кибердетекторов с
                  искусственным интеллектом - защищена патентом и авторскими правами.
                </p>

                <Extender>
                  <p className="index-page__desc">
                    Клиент - сервер:
                    <ul>
                      <li>агент устанавливается в точку в зависимости от модели угроз и топологии сети</li>
                      <li>связь агента и сервера (нейросети) по закрытому протоколу и API</li>
                      <li>инсталляция производится администратором сети или сервером в автоматическом режиме.</li>
                    </ul>
                  </p>
                  <p className="index-page__desc">
                    Алгоритмы работы
                    <ul>
                      <li>
                        Агент производит сбор данных по сетевой активности, программного обеспечения и аппаратных
                        средств
                      </li>
                      <li>Подготавливает данные для передачи и классификации на сервер</li>
                      <li>Нейронная сеть классифицирует переданные данные и проводит идентификацию угроз вторжения</li>
                      <li>
                        Сервер выводит уведомления об угрозе и передаёт сообщения администратору сети или модулю
                        автоматического реагирования.
                      </li>
                    </ul>
                  </p>
                  <p className="index-page__desc">
                    Масштабируемость решения:
                    <ul>
                      <li>
                        Система масштабируется путем расширения обучающей модели, сетевого охвата, интегрируется
                        посредством стандартных сетевых протоколов
                      </li>
                      <li>
                        Алгоритм может быть расширен за счет увеличения количества используемых ИНС и применения других
                        типов ИНС в зависимости от поставленной задачи
                      </li>
                      <li>
                        Возможна интеграция как на сетевом уровне, так и на уровне модели угроз гетерогенных и
                        моносетей.
                      </li>
                    </ul>
                  </p>
                  <p className="index-page__desc">
                    Суть научной новизны продукта:
                    <ul>
                      <li>
                        Применение нейронной сети, использование потенциала «самообучения» и выход за пределы экспертной
                        системы, а также повышение точности определения сетевых аномалий новых видов, особенно в
                        отношении сред интернета вещей и умных устройств, что отличает наш продукт от классических
                        систем безопасности, требующих постоянного обновления базы и проводящими анализ по заранее
                        заданному шаблону
                      </li>
                      <li>
                        Уникальный метод сокращения объема ключевой информации для классификации угроз, что позволяет
                        существенно сократить трафик в сети, а также уменьшить вероятность перехвата ценной информации,
                        эта реализация отличается от известных систем, передающих на детектор полные информационные
                        блоки
                      </li>
                      <li>
                        Радиоконтроль беспроводных сетей, сетей умных устройств IoT, анализ сетевых сервисов, это
                        отличает продукт от существующих решений в части среды обеспечения кибербезопасности
                      </li>
                      <li>
                        Уникальные алгоритмы машинного обучения - применение слоев Кохонена, что позволяет увеличить
                        сходимость решений, это отличает создаваемый продукт от других нейросетевых детекторов
                        киберугроз высокой точностью и скоростью.
                      </li>
                    </ul>
                  </p>
                  <p className="index-page__desc">
                    Наша система в максимуме «из коробки» включает в себя:
                    <ul>
                      <li>Платформу сбора, анализа и корреляции событий;</li>
                      <li>
                        Локальную систему обнаружения и предотвращения вторжений (включая анализ исполняемых кодов и
                        подключаемых устройств, анализ протоколов 7 уровня OSI)
                      </li>
                      <li>Программный модуль первичной обработки информации;</li>
                      <li>Программный модуль взаимодействия с API;</li>
                      <li>
                        Сетевую систему обнаружения и предотвращения вторжений с модулями интеграции в межсетевые экраны
                        и сервисные службы;
                      </li>
                      <li>
                        Беспроводную систему обнаружения вторжений - включая умные устройства (Интернет-вещей) и
                        активность в сетях BT, BLE, ZigBee, Lora и т.д.;
                      </li>
                      <li>Мониторинг узлов сети;</li>
                      <li>
                        Анализ сетевых аномалий + включая беспроводные радио аномалии и аномалии сетей умных устройств
                        (Интернет-вещей);
                      </li>
                      <li>Программный модуль мониторинга, сигнализации и контроля;</li>
                      <li>Сканер уязвимостей – отдельным продуктом и как дополнение к настройке системы из коробки;</li>
                      <li>Система обмена и переноса обученных моделей машинного обучения (нейросеть);</li>
                      <li>
                        Множество специализированных плагинов для парсинга и корреляции логов со всевозможных внешних
                        устройств и служб, открытый API для разработки внешний модулей и плагинов;
                      </li>
                      <li>Платформа биллинга стоимости предоставляемых услуг в локальном и облачном решении.</li>
                    </ul>
                  </p>
                  <p className="index-page__desc">Результаты тестирования NGIDS NeuroFortress</p>
                </Extender>
              </div>
            </div>
          </div>
        </section>
        <section className="index-page__section">
          <div className="index-page__section-wrapper">
            <div className="index-page__title centered">Нам доверяют</div>
            <div className="partners">
              <div className="partners__list">
                <div className="partners__item"></div>
                <a href="#1" target="_blank" className="partner">
                  <div className="partner__logo"></div>
                  <div className="partner__title">ЮРАЦ НИИ “СВА”</div>
                </a>
                <a href="#1" target="_blank" className="partner">
                  <div className="partner__logo"></div>
                  <div className="partner__title">ООО НПФ "ТехноСквер"</div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="index-page__section">
          <div className="index-page__section-wrapper">
            <div className="index-page__title centered">Защитите свой бизнес сейчас и ничего не теряйте в будущем!</div>
            {/*<div className="index-page__desc centered">Доступ к системе предоставляется по подписке:</div>*/}

            {/*<div className="pricing">
              <div className="pricing__wrapper">
                <div className="pricing__item">
                  <div className="pricing__title">Облачное решение</div>
                  <div className="pricing__desc">
                    Нет необходимости разворачивать систему локально, анализ и статистика производятся в облаке
                  </div>
                  <div className="pricing__list">
                    <div className="pricing__list-item">
                      Малый офис – 5 устройств – <span>$20</span>
                    </div>
                    <div className="pricing__list-item">
                      Средний офис – 50 устройств - <span>$18</span>
                    </div>
                  </div>
                </div>
                <div className="pricing__item main">
                  <div className="pricing__title">Стандартное решение</div>
                  <div className="pricing__desc">
                    Система разворачивается локально + поддержка контроля сетей IoT (опционально)
                  </div>
                  <div className="pricing__list">
                    <div className="pricing__list-item">
                      Малый офис – 5 устройств - <span>$28</span>
                    </div>
                    <div className="pricing__list-item">
                      Средний офис – 50 устройств - <span>$24</span>
                    </div>
                    <div className="pricing__list-item">
                      Корпорация – от 500 устройств - <span>$18</span>
                    </div>
                    <div className="pricing__list-item">
                      Опция – Контроль IoT сетей – от <span>$5</span>
                    </div>
                  </div>
                </div>
                <div className="pricing__item">
                  <div className="pricing__title">Корпоративное решение</div>
                  <div className="pricing__desc">Локальная система + контроль сетей IoT</div>
                  <div className="pricing__list">
                    <div className="pricing__list-item">
                      Средний офис – 50 устройств - <span>$28</span>
                    </div>
                    <div className="pricing__list-item">
                      Корпорация – от 500 устройств - <span> $21</span>
                    </div>
                  </div>
                </div>
              </div>
                        </div>*/}
          </div>
        </section>
        <section className="index-page__section">
          <div className="index-page__section-wrapper">
            <div className="index-page__section-row">
              <div className="index-page__section-col">
                <img width="300px" src={require('../../images/advantages_3.png')} alt="" />
              </div>
              <div className="index-page__section-col">
                <div className="index-page__title">
                  Чем инновационной NGIDS NeuroFortress лучше стандартных средств защиты?
                </div>
                <p className="index-page__desc">
                  Расширенная аналитика На основе анализа угроз, машинного обучения и алгоритмов глубокого обучения,
                  которые помогут вам выявлять важные активности в инфраструктуре безопасности и определять их
                  приоритетность. Ускоренное расследование и реагирование
                </p>
                <p className="index-page__desc">
                  Интегрированный анализ угроз предоставляет Вам поведенческий анализ возникающих угроз, чтобы лучше
                  выявлять подозрительную активность, которая обходит ваши текущие системы безопасности.
                </p>
                <p className="index-page__desc">
                  Прикладная разведка сообщества Все сведения о событиях и инцидентах безопасности распространяются
                  среди нашей разнообразной клиентской базы, что помогает вам оставаться в курсе новых атак.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="index-page__section">
          <div className="index-page__section-wrapper">
            <div className="index-page__title">Как подключить или заказать опытную эксплуатацию</div>
            <div className="index-page__section-row">
              <div className="index-page__section-col">
                <img width="300px" src={require('../../images/conect_4.jpg')} alt="" />
              </div>
              <div className="index-page__section-col">
                <p className="index-page__desc">
                  4 шага и все готово!
                  <ol>
                    <li>Оставить заявку</li>
                    <li>С Вами свяжутся наши специалисты</li>
                    <li>Подключить необходимую конфигурацию</li>
                    <li>Оплатить.</li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="index-page__section">
          <div className="index-page__section-wrapper">
            <div className="index-page__section-row">
              <div className="index-page__section-col">
                <ContactForm formMethod={submitForm} />
              </div>
              <div className="index-page__section-col">
                <Contacts />
              </div>
            </div>
          </div>
        </section>
        <Map />
      </div>
    </div>
  )
}

export default IndexPage
