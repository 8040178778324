import React, {useEffect, useState} from 'react'

import RegForm from '../../components/blocks/forms/RegisterForm'

import {ProfileToken} from '../../helpers/token'
import {registration} from '../../services/user'

const RegPage = ({history}) => {
  const submitForm = data => registration(data).then(() => setSended(true))

  const [isSended, setSended] = useState(false)

  useEffect(() => {
    if (ProfileToken.get()) history.push('/cabinet')
  }, [history])

  return (
    <div className="login-page">
      <div className="login-page__wrapper">
        <div className="login-page__title">
          NGIDS <span className="version">NeuroFortress</span>
        </div>

        {!isSended && (
          <div className="login-page__form">
            <RegForm formName="Sign Up" formMethod={submitForm} />
          </div>
        )}
        {isSended && (
          <div className="login-page__message">Further instructions have been sent to your e-mail address</div>
        )}
      </div>
    </div>
  )
}

export default RegPage
