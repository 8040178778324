import React, {useState, useEffect} from 'react'
import Preloader from '../../../ui/Preloader'
import Button from '../../../ui/CustomButton'
import {useTranslation} from 'react-i18next'

import './style.scss'

const Agent = ({agent, toggleagent, onClickSettings, agentMethods}) => {
  const {t} = useTranslation()

  const [isLoading, setLoading] = useState(false)
  const [currentState, setState] = useState()

  const STATE_CONSTS = {
    0: 'wait',
    1: 'work',
    2: 'disconnect',
  }

  const buttonColor = () => {
    switch (currentState) {
      case 'disconnect':
        return 'grey'
      case 'work':
        return 'red'
      case 'wait':
        return 'green'
      default:
        return 'blue'
    }
  }

  const statusColor = () => {
    switch (currentState) {
      case 'disconnect':
        return 'red'
      case 'work':
        return 'green'
      case 'wait':
        return 'yellow'
      default:
        return 'grey'
    }
  }

  const buttonText = () => {
    switch (currentState) {
      case 'disconnect':
        return t('agent.off')
      case 'work':
        return t('agent.stop')
      case 'wait':
        return t('agent.start')
      default:
        return ''
    }
  }

  useEffect(() => {
    if (agent.status) setState(STATE_CONSTS[agent.status])

    return () => {
      setState('')
    }
  }, [STATE_CONSTS, agent])

  useEffect(() => {
    if (agent) {
      if (agent.state !== agent.status) {
        setLoading(true)
      } else {
        setLoading(false)
      }
    }
  }, [agent])

  const isA = agent.active_iface === 'classifier'

  return (
    <div className={`agent ${agent.active ? 'active' : ''} `}>
      <div className="agent__head">
        <div className="agent__ip">
          {t('agent.ip')}: {agent.ip_address}
        </div>

        <div className="agent__ident">
          {t('agent.interface')}: {agent.active_iface}
        </div>
        <div className="agent__name">
          {t('agent.name')}: {agent.name}
        </div>
        {isA ? (
          <div
            className="agent__logo"
            style={{
              backgroundImage: `url('https://api.ngids.com/ids/static/media/neuro.png')`,
            }}></div>
        ) : (
          <div
            className="agent__logo"
            style={{
              backgroundImage: `url('https://api.ngids.com/ids/static/media/111.png')`,
            }}></div>
        )}
      </div>
      <div className={`agent__wrapper `}>
        {/* {isA ? <div className="agent__num">Нейросетевой детектор</div> : <div></div>} */}

        <div className="agent__col">
          {!isA && (
            <div className={`agent__buttons ${isLoading ? 'loading' : ''}`}>
              {isLoading && <Preloader transparent={true} show />}

              <div className="agent__button">
                <Button
                  text={buttonText()}
                  color={buttonColor()}
                  onClick={() => {
                    setLoading(true)
                    if (currentState === 'work') agentMethods['off'](agent.id)
                    if (currentState === 'wait') agentMethods['on'](agent.id)
                  }}
                />
              </div>
              <div className="agent__button">
                <Button
                  text={t('agent.settings')}
                  color={currentState === 'wait' ? 'blue' : 'grey'}
                  onClick={() => {
                    currentState === 'wait' && onClickSettings(agent)
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="agent__col">
          <div className="agent__item">
            <div className={`agent__status`}>
              {t('agent.status')}
              <span className={statusColor()}> {currentState}</span>
            </div>
          </div>
          <div className="agent__item">
            {agent.service_class === '0' && agent.attacks.length > 0 && (
              <div className="agent__num">
                {t('agent.attacks')}
                {agent.attacks.map((item, i) => (
                  <span key={i}>
                    {item.name}: {item.count}
                  </span>
                ))}
              </div>
            )}
            {agent.service_class === '1' && (
              <div className="agent__num">
                <span>
                  {t('agent.center')}: {agent.params.center_freq}
                </span>
                <span>
                  {t('agent.band')}: {agent.params.delta_freq}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="agent__col">
          <div className="agent__item">
            {currentState === 'disconnect' && (
              <div className="agent__date">
                {t('agent.disconnect')}: {agent.date}{' '}
              </div>
            )}
            {currentState === 'work' && (
              <div className="agent__date">
                {t('agent.work')}: {agent.date}{' '}
              </div>
            )}
            {currentState === 'wait' && (
              <div className="agent__date">
                {t('agent.downtime')}: {agent.date}{' '}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Agent
