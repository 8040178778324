import axios from 'axios'
import {ProfileToken} from '../helpers/token'

export const getShortLog = async id => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/api/log30last`, {
      headers: {
        Authorization: `token ${ProfileToken.get()}`,
      },
      params: {
        area_id: id,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}

export const getNetworks = async formData => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/api/area`, {
      headers: {
        Authorization: `token ${ProfileToken.get()}`,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}

export const getServices = async id => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/api/services_running`, {
      headers: {
        Authorization: `token ${ProfileToken.get()}`,
      },
      params: {
        area_id: id,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}

export const getAttacs = async id => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/api/attacks`, {
      headers: {
        Authorization: `token ${ProfileToken.get()}`,
      },
      params: {
        area_id: id,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}

export const getGraph = async id => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/api/graphdots`, {
      headers: {
        Authorization: `token ${ProfileToken.get()}`,
      },
      params: {
        area_id: id,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}

export const getAdditionGraph = async id => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/api/graphdotsold`, {
      headers: {
        Authorization: `token ${ProfileToken.get()}`,
      },
      params: {
        area_id: id,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}

export const getAdditionalStats = async id => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/api/statall`, {
      headers: {
        Authorization: `token ${ProfileToken.get()}`,
      },
      params: {
        area_id: id,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}

// export const getAgentInterfaces = async id => {
//   try {
//     const res = await axios.post(
//       `${process.env.REACT_APP_API_ADDRESS}/api/service/update/available_interfaces/${id}/`,
//       {},
//       {
//         headers: {
//           Authorization: `token ${ProfileToken.get()}`,
//         },
//       },
//     )
//     return res.data
//   } catch (err) {
//     throw err
//   }
// }

export const getAgentInterfaces = async id => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/api/service/list/${id}/`, {
      headers: {
        Authorization: `token ${ProfileToken.get()}`,
      },
      params: {
        area_id: id,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}

export const getCurrentStatus = async id => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/api/meanservicestatus`, {
      headers: {
        Authorization: `token ${ProfileToken.get()}`,
      },
      params: {
        area_id: id,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}

export const setAllServicesOn = async id => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/api/allseton`,
      {
        area_id: id,
      },
      {
        headers: {
          Authorization: `token ${ProfileToken.get()}`,
        },
      },
    )
    return res.data
  } catch (err) {
    throw err
  }
}

export const setAllServicesOff = async id => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/api/allsetoff`,
      {
        area_id: id,
      },
      {
        headers: {
          Authorization: `token ${ProfileToken.get()}`,
        },
      },
    )
    return res.data
  } catch (err) {
    throw err
  }
}

export const setServiceOn = async id => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/api/seton`,
      {
        service_id: id,
      },
      {
        headers: {
          Authorization: `token ${ProfileToken.get()}`,
        },
      },
    )
    return res.data
  } catch (err) {
    throw err
  }
}

export const setServiceOf = async id => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/api/setoff`,
      {
        service_id: id,
      },
      {
        headers: {
          Authorization: `token ${ProfileToken.get()}`,
        },
      },
    )
    return res.data
  } catch (err) {
    throw err
  }
}

export const setSettings = async ({data, id}) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/api/service/update/active_interface/${id}/`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `token ${ProfileToken.get()}`,
        },
      },
    )
    return res.data
  } catch (err) {
    throw err
  }
}
