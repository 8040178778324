import React from 'react'
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import marker from '../../../../images/marker-icon-2x.png'
import L from 'leaflet'

import './style.scss'
const newicon = new L.icon({
  iconUrl: marker,
  iconSize: [20, 30],
})

const Map = () => {
  const COORDS = [47.23699, 39.73651]

  return (
    <div className="map">
      <MapContainer center={COORDS} zoom={13} scrollWheelZoom={false} style={{width: '100%', height: '99%'}}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={COORDS} icon={newicon}>
          <Popup>Театральный пр., 85, Ростов-на-Дону, Ростовская обл., 344016</Popup>
        </Marker>
      </MapContainer>
    </div>
  )
}

export default Map
