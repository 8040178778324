import React from 'react'
// import {useTranslation} from 'react-i18next'

import './style.scss'

const Contacts = () => {
  // const {t} = useTranslation()

  return (
    <div className="contacts">
      <div className="contacts__title">Контактная информация</div>
      <div className="contacts__row">
        <div className="contacts__item">
          <div className="contacts__subtitle email">Email</div>
          <div className="contacts__desc">cva(ā)cvatec•com</div>
          <div className="contacts__desc">vav(ā)cvatec•com</div>
        </div>
      </div>
      <div className="contacts__row">
        <div className="contacts__item">
          <div className="contacts__subtitle phone">Телефон</div>
          <div className="contacts__desc">+7-(900)130-6663</div>
          <div className="contacts__desc">+7-(900)130-3666</div>
        </div>
      </div>
      <div className="contacts__row">
        <div className="contacts__item">
          <div className="contacts__subtitle address">Адрес</div>
          <div className="contacts__desc">Театральный пр., 85,</div>
          <div className="contacts__desc">Ростов-на-Дону,</div>
          <div className="contacts__desc">Ростовская обл., 344016</div>
        </div>
      </div>
    </div>
  )
}

export default Contacts
