import React from 'react'

import './style.scss'

const LogSmall = ({data = []}) => {
  let list = data.map((item, i) => {
    return (
      <div className="log-small__item" key={i}>
        <div className="log-small__item-name">{item.name}</div>
        <div className="log-small__item-type">{item.type}</div>
        <div className="log-small__item-date">{item.date}</div>
      </div>
    )
  })
  return <div className="log-small">{list}</div>
}

export default LogSmall
