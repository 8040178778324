import React, {useContext, useEffect} from 'react'

import CustomButton from '../../ui/CustomButton'

import {getUserData} from '../../../services/user'
import UserContext from '../../../context/UserContext'
import {ProfileToken} from '../../../helpers/token'
import {useTranslation} from 'react-i18next'

import './style.scss'

const Header = ({history}) => {
  const {t} = useTranslation()
  const {userData, updateUserData} = useContext(UserContext)

  useEffect(() => {
    if (checkLogin())
      getUserData().then(res => {
        updateUserData(res)
      })
  }, [updateUserData])

  const checkLogin = () => {
    return ProfileToken.get()
    //&& userData
  }

  const logOut = () => {
    history.push('/login')
    ProfileToken.remove()
    updateUserData(null)
    // UserId.remove()
  }

  return (
    <div className="header">
      <div className="header__wrapper">
        <div className="header__title" onClick={e => history.push('/')}>
          NGIDS <span className="version">NeuroFortress</span>
        </div>
        {checkLogin() && (
          <div className="header__menu">
            <div className="header__link" onClick={e => history.push('/cabinet')}>
              {t('header.cabinet')}
            </div>
          </div>
        )}
        <div className="header__user">
          <>
            {!checkLogin() && (
              <>
                <div className="header__link" onClick={e => history.push('/login')}>
                  {t('header.signin')}
                </div>
                <div className="header__btn">
                  <CustomButton text={t('header.signup')} color="blue" onClick={e => history.push('/registration')} />
                </div>
              </>
            )}
            {checkLogin() && (
              <>
                {userData && <div className="header__username">{userData.username}</div>}
                <div className="header__link" onClick={e => logOut()}>
                  {t('header.logout')}
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  )
}

export default Header
