import React, {useEffect, useContext} from 'react'

import LoginForm from '../../components/blocks/forms/LoginForm'

import UserContext from '../../context/UserContext'
import {userLogin, getUserData} from '../../services/user'
import {ProfileToken} from '../../helpers/token'

import './style.scss'

const LoginPage = ({history}) => {
  const {updateUserData} = useContext(UserContext)

  useEffect(() => {
    if (ProfileToken.get()) history.push('/')
  }, [history])

  const submitForm = data =>
    userLogin(data).then(response => {
      ProfileToken.set(response.token)
      getUserData().then(res => updateUserData(res))
      history.push('/cabinet')
    })

  return (
    <div className="login-page">
      <div className="login-page__wrapper">
        <div className="login-page__about">What is it?</div>
        <br></br>
        <div className="login-page__about">An innovative system using a hybrid neural network for Cybersecurity.</div>
        <br></br>
        <div className="login-page__about">About us</div>
        <br></br>
        <div className="login-page__about">We are a startup, please write to us at vav@cvatec.com</div>
        <br></br>
        <div className="login-page__title">
          NGIDS <span className="version">NeuroFortress</span>
        </div>
        <br></br>
        <div className="login-page__form">
          <LoginForm formName="Log In" formMethod={submitForm} />
        </div>
      </div>
    </div>
  )
}

export default LoginPage
