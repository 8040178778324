import React, {useState} from 'react'
import {useForm} from 'react-hook-form'

import CustomInput from '../../../ui/CustomInput'
import CustomButton from '../../../ui/CustomButton'
import Preloader from '../../../ui/Preloader'

import './../style.scss'

const LoginForm = ({formMethod}) => {
  const {register, handleSubmit, errors} = useForm()
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const onSubmit = data => {
    setError(null)
    setLoading(true)
    formMethod(data)
      .then(r => {
        setError(null)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        e.response && e.response.data.non_field_errors.length > 0
          ? setError(e.response.data.non_field_errors[0])
          : setError(e.response.data.message)
      })
  }

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className={`form__wrapper ${isLoading ? 'disabling' : ''}`}>
        <div className="form__title">Sign In</div>
        <div className="form__input">
          <CustomInput
            inputName="username"
            inputRef={register({required: true, minLength: 4, maxLength: 20})}
            isError={errors.username}
            inputPlaceholder="Login"
          />
          {errors.username && errors.username.type === 'required' && (
            <span className="form__error-label">This field is required</span>
          )}
          {errors.username && (errors.username.type === 'maxLength' || errors.username.type === 'minLength') && (
            <span className="form__error-label">Login must be at least 4 characters and maximun of 20</span>
          )}
        </div>
        <div className="form__input">
          <CustomInput
            inputName="password"
            inputRef={register({required: true, minLength: 8, maxLength: 30})}
            inputType="password"
            isError={errors.password}
            inputPlaceholder="Password"
          />
          {errors.password && errors.password.type === 'required' && (
            <span className="form__error-label">This field is required</span>
          )}
          {errors.password && (errors.password.type === 'maxLength' || errors.password.type === 'minLength') && (
            <span className="form__error-label">Password must be at least 8 characters and maximun of 30</span>
          )}
        </div>
        <div className="form__btn">
          <CustomButton type="submit" className="big" text="Sign In" color="blue" />
        </div>
        {error && (
          <span className="form__send-error">{error.isArray ? error.map(e => <p>{e.message}</p>) : error}</span>
        )}
        {isLoading && <Preloader transparent={true} show />}
      </div>
    </form>
  )
}

export default LoginForm
