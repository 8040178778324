import React from 'react'
import {i18n} from '../../../i18n'

import './style.scss'

const LangSwitcher = ({code}) => {
  const changeLanguage = lng => i18n.changeLanguage(lng)
  const langs = ['ru', 'en']

  return (
    <div className="lang-switcher">
      {langs.map((lang, i) => {
        return (
          <div
            key={i}
            className={`lang-switcher__btn ${lang === code ? 'active' : ''}`}
            onClick={() => {
              changeLanguage(lang)
            }}>
            {lang}
          </div>
        )
      })}
    </div>
  )
}

export default LangSwitcher
