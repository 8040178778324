import React, {useEffect, useState} from 'react'
import LogSmall from '../../components/blocks/cabinet/SmallLog'
import {
  getShortLog,
  getNetworks,
  getServices,
  getAttacs,
  setAllServicesOn,
  setAllServicesOff,
  setServiceOn,
  setServiceOf,
  getGraph,
  getAdditionGraph,
  setSettings,
  getCurrentStatus,
  getAgentInterfaces,
} from '../../services/networks'

import {useTranslation} from 'react-i18next'

import useInterval from '../../helpers/useInterval'

import NetworkList from '../../components/blocks/cabinet/NetworkList'
import NetworkAnalyze from '../../components/blocks/cabinet/NetworkAnalyze'
import Tumbler from '../../components/blocks/cabinet/Tumbler'
import AgentsList from '../../components/blocks/cabinet/AgentsList'
import Settings from '../../components/blocks/cabinet/Settings'
import './style.scss'

const CabinetPage = ({history}) => {
  const {t} = useTranslation()

  const [timer, setTimer] = useState(7000)
  const [events, setEvents] = useState([])
  const [networks, setNetworks] = useState([])
  const [agents, setAgents] = useState([])
  const [activeNetwork, setNetwork] = useState()
  const [status, setStatus] = useState()
  const [summary, setSummary] = useState()
  const [graphs, setGraphs] = useState()
  const [additonGraphs, setAdditonGraphs] = useState()

  //settings
  const [isShowSettings, setShowSettings] = useState(false)
  const [activeAgent, setActiveAgent] = useState({})
  //misc
  const [isLoading, setLoading] = useState(false)
  const [isGraphsLoading, setGraphsLoading] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  useInterval(() => {
    getNetworkData(activeNetwork && activeNetwork.id)
  }, timer)

  useEffect(() => {
    getNetworks().then(response => {
      setNetworks(response)
      setNetwork(response[0])
    })

    return function cleanup() {
      setNetworks([])
      resetData()
    }
  }, [])

  useEffect(() => {
    if (activeNetwork) {
      getNetworkData(activeNetwork.id)
      getAdditionGraph(activeNetwork.id).then(response => {
        setSummary(response.sidebar)
        setAdditonGraphs(response)
      })
    }
  }, [activeNetwork])

  const changeNetwork = network => {
    setTimer(7000)
    setNetwork(network)
  }

  const openSettings = agent => {
    getAgentInterfaces(agent.id).then(response => {
      setActiveAgent(response)
      setShowSettings(true)
    })
  }

  const closeSettings = () => {
    setShowSettings(false)
    setActiveAgent()
  }

  const getNetworkData = id => {
    setLoading(true)
    let shortLog = getShortLog(id).then(response => setEvents(response.log))
    let services = getServices(id).then(response => {
      // setAgents(response)
    })
    let graph = getGraph(id).then(response => {
      setGraphs(response)
    })
    // getAdditionalStats(id).then(response => {
    //   console.log('addit stats', response)
    //   setSummary(response)
    // })
    let attacks = getAttacs(id).then(response => setAgents(response))
    let status = getCurrentStatus(id).then(response => {
      setStatus(response)
    })
    Promise.all([shortLog, services, graph, attacks, status])
      .then(values => {
        setLoading(false)
      })
      .catch(e => setLoading(false))
  }

  const setAllOff = id => {
    setIsButtonLoading(true)
    setAllServicesOff(id).then(response => {
      setIsButtonLoading(false)
    })
  }

  const setAllOn = id => {
    setIsButtonLoading(true)
    setAllServicesOn(id).then(response => {
      setIsButtonLoading(false)
    })
  }

  const setAgentOn = id => {
    setServiceOn(id)
  }

  const setAgentOff = id => {
    setServiceOf(id)
  }

  const saveSettings = data => {
    setSettings({data: data, id: activeAgent.id}).then(response => {
      closeSettings()
    })
  }

  const refreshMethod = () => {
    setGraphsLoading(true)
    getAdditionGraph(activeNetwork.id).then(response => {
      setGraphsLoading(false)
      setAdditonGraphs(response)
      setSummary(response.sidebar)
    })
  }

  const resetData = () => {
    setNetwork()
    setEvents([])
    setStatus()
    setAgents([])
    setGraphs()
    setSummary()
  }

  useInterval(() => {
    getNetworkData(activeNetwork && activeNetwork.id)
  }, 7000)

  return (
    <div className="cabinet-page">
      <div className="cabinet-page__wrapper">
        <div className="cabinet-page__head">
          <div className="cabinet-page__title">{t('cabinet.title')}</div>
          <div className="cabinet-page__desc">{t('cabinet.desc')}</div>
        </div>
        <section className="cabinet-page__section">
          <div className="cabinet-page__sidebar">
            <div className="sidebar">
              <div className="sidebar__network">{activeNetwork && activeNetwork.name}</div>
              <div className="sidebar__buttons">
                <Tumbler type="Detectors" status={status && status['statusdet']} />
                <Tumbler
                  type="Agents"
                  status={status && status['statusagent']}
                  methods={{off: setAllOff, on: setAllOn}}
                  isLoading={isButtonLoading}
                  activeNetwork={activeNetwork}
                />
              </div>
              <div className="sidebar__log">
                <div className="sidebar__title">{t('sidebar.log')}</div>
                <LogSmall data={events} />
              </div>
            </div>
          </div>
          <div className="cabinet-page__main">
            <div className="cabinet-page__swicher">
              <NetworkList
                onChange={changeNetwork}
                reset={resetData}
                networks={networks}
                activeNetwork={activeNetwork}
                isLoading={isLoading}
              />
            </div>
            <div className="cabinet-page__summary">
              <NetworkAnalyze
                graphs={graphs}
                additonGraphs={additonGraphs}
                summary={summary}
                refreshMethod={refreshMethod}
                isGraphsLoading={isGraphsLoading}
              />
            </div>
            <div className="cabinet-page__network-list">
              <AgentsList
                agents={agents}
                openSettings={openSettings}
                agentMethods={{off: setAgentOff, on: setAgentOn}}
              />
              {isShowSettings && <Settings saveMethod={saveSettings} agent={activeAgent} close={closeSettings} />}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default CabinetPage
