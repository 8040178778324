import React from 'react'

import './style.scss'

const NetworkList = ({networks = [], activeNetwork = {}, onChange, reset, isLoading = false}) => {
  let list = networks.map((network, i) => {
    return (
      <div
        key={i}
        className={`network-list__item ${network.id === activeNetwork.id ? 'active' : ''}`}
        onClick={e => {
          if (!isLoading) {
            reset()
            onChange(network)
          }
        }}>
        {network.name}
      </div>
    )
  })
  return <div className="network-list">{list}</div>
}

export default NetworkList
