import React from 'react'
import {Line} from 'react-chartjs-2'
import Button from '../../../ui/CustomButton'
import {useTranslation} from 'react-i18next'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {Bar} from 'react-chartjs-2'

import './style.scss'

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend)

const NetworkAnalyze = ({graphs = {}, additonGraphs = {}, summary = {}, refreshMethod, isGraphsLoading = false}) => {
  const {t} = useTranslation()

  const {attack_normal, day_attacks, week_attacks, topattacks} = summary
  const {attack_hours, normal_hours} = graphs
  const {normal_days, attack_days, attack_weeks, normal_weeks} = additonGraphs

  const barsOptions = {
    animation: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
  }

  const barsDayData = {
    labels:
      attack_days &&
      attack_days.map(item => {
        return Object.keys(item)
      }),
    datasets: [
      {
        label: t('analyze.attacksPerDay'),
        data:
          attack_days &&
          attack_days.map(item => {
            return item[Object.keys(item)]
          }),
        backgroundColor: 'rgba(255, 99, 132, 0.8',
      },
      {
        label: t('analyze.normal'),
        data:
          normal_days &&
          normal_days.map(item => {
            return item[Object.keys(item)]
          }),
        backgroundColor: 'rgb(43, 215, 132, 0.8)',
      },
    ],
  }

  const barsWeeksData = {
    labels:
      attack_weeks &&
      attack_weeks.map(item => {
        return Object.keys(item)
      }),
    datasets: [
      {
        label: t('analyze.attacksPerWeek'),
        data:
          attack_weeks &&
          attack_weeks.map(item => {
            return item[Object.keys(item)]
          }),
        backgroundColor: 'rgba(255, 99, 132, 0.8)',
      },
      {
        label: t('analyze.normal'),
        data:
          normal_weeks &&
          normal_weeks.map(item => {
            return item[Object.keys(item)]
          }),
        backgroundColor: 'rgb(43, 215, 132, 0.8)',
      },
    ],
  }

  const data = {
    labels:
      attack_hours &&
      attack_hours.map(item => {
        return Object.keys(item)
      }),
    datasets: [
      {
        label: t('agent.attacks'),
        data:
          attack_hours &&
          attack_hours.map(item => {
            return item[Object.keys(item)]
          }),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y',
      },
      {
        label: t('analyze.normal'),
        data:
          normal_hours &&
          normal_hours.map(item => {
            return item[Object.keys(item)]
          }),
        borderColor: 'rgb(43, 215, 132)',
        backgroundColor: 'rgb(43, 215, 132, 0.5)',
        yAxisID: 'y',
      },
    ],
  }

  const options = {
    spanGaps: true, // enable for all datasets

    animation: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    datasets: {
      line: {
        pointRadius: 0,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    stacked: false,
    plugins: {},
    scales: {
      y: {
        type: 'logarithmic',
        display: true,
        position: 'left',
      },
      y1: {
        type: 'logarithmic',
        display: false,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  }

  const topList =
    topattacks &&
    topattacks.map((attack, i) => (
      <div className="top-list__item" key={i}>
        <div className="top-list__wrapper">
          <div className="top-list__col">
            <div className="top-list__counter">
              <div className="top-list__title">{t('analyze.region')}:</div>
              <div className="top-list__region">{attack.region}</div>
            </div>
            <div className="top-list__counter">
              <div className="top-list__title">{t('analyze.type')}:</div>
              <div className="top-list__type">{attack.attack}</div>
            </div>
            <div className="top-list__counter">
              <div className="top-list__title">{t('analyze.ip')}:</div>
              <div className="top-list__ip">{attack.IP}</div>
            </div>
            <div className="top-list__counter">
              <div className="top-list__title">{t('analyze.agent')}:</div>
              <div className="top-list__agent">{attack.agent}</div>
            </div>
          </div>
          <div className="top-list__count">{attack.count}</div>
        </div>
      </div>
    ))

  return (
    <div className="network-analyze">
      <div className="network-analyze__main">
        <div className="network-analyze__title">{t('analyze.lastActivity')}</div>
        <div className="network-analyze__graph">
          <Line data={data} options={options} />
        </div>
        <div className="network-analyze__title">{t('analyze.attacksPerDay')}</div>
        <div className="network-analyze__graph">
          <Bar options={barsOptions} data={barsDayData} />;
        </div>
        <div className="network-analyze__title">{t('analyze.attacksPerWeek')}</div>
        <div className="network-analyze__graph">
          <Bar options={barsOptions} data={barsWeeksData} />;
        </div>
        <Button
          isLoading={isGraphsLoading}
          text={t('analyze.refresh')}
          onClick={refreshMethod}
          color={isGraphsLoading ? 'grey' : 'blue'}
        />
      </div>
      <div className="network-analyze__sidebar">
        <div className="network-analyze__title">{t('analyze.summary')}</div>
        <div className="network-analyze__stats">
          <div className="stats">
            <div className="stats__title">{t('analyze.normal')}:</div>
            <div className="stats__count">{attack_normal}</div>
          </div>
          <div className="stats">
            <div className="stats__title">{t('analyze.attacksPerDay')}:</div>
            <div className="stats__count">{day_attacks}</div>
          </div>
          <div className="stats">
            <div className="stats__title">{t('analyze.attacksPerWeek')}:</div>
            <div className="stats__count">{week_attacks}</div>
          </div>
        </div>
        <div className="network-analyze__title">{t('analyze.top')}</div>
        <div className="network-analyze__top">
          <div className="top-list">{topList}</div>
        </div>
      </div>
    </div>
  )
}

export default NetworkAnalyze
