import React, {useEffect, useState} from 'react'
import Button from '../../../ui/CustomButton'
import {useTranslation} from 'react-i18next'

import './style.scss'

const Tumbler = ({type, status, methods, activeNetwork}) => {
  const {t} = useTranslation()

  const [currentStatus, setStatus] = useState('')
  const [isLoading, setLoading] = useState(false)

  const MEAN_STATUS = {
    0: 'allon',
    1: 'alloff',
    2: 'mean',
  }

  useEffect(() => {
    if (status) setStatus(MEAN_STATUS[status])

    return () => {
      setStatus('')
    }
  }, [MEAN_STATUS, status])

  const toggleAll = () => {
    if (currentStatus === 'allon') methods['off'](activeNetwork.id)
    if (currentStatus === 'alloff' || 'mean') methods['on'](activeNetwork.id)
  }

  const setAllOff = () => {
    methods['off'](activeNetwork.id)
  }

  const buttonColor = () => {
    switch (currentStatus) {
      case 'alloff':
        return 'green'
      case 'allon':
        return 'red'
      case 'mean':
        return 'green'

      default:
        return 'blue'
    }
  }

  const buttonText = () => {
    switch (currentStatus) {
      case 'alloff':
        return t('sidebar.startAll')
      case 'allon':
        return t('sidebar.stopAll')
      case 'mean':
        return t('sidebar.startAll')
      default:
        return ' '
    }
  }

  const statusText = () => {
    switch (currentStatus) {
      case 'alloff':
        return t('sidebar.allOn')
      case 'allon':
        return t('sidebar.allOff')
      case 'mean':
        return t('sidebar.mean')
      default:
        return ' '
    }
  }

  const statusColor = () => {
    switch (currentStatus) {
      case 'alloff':
        return 'red'
      case 'allon':
        return 'green'
      case 'mean':
        return 'yellow'
      default:
        return 'grey'
    }
  }

  return (
    <div className="tumbler">
      <div className="tumbler__title">{type}</div>
      <div className={`tumbler__status }`}>
        {t('agent.status')}: <span className={`${statusColor()}`}>{statusText()}</span>
      </div>
      {methods && (
        <div className="tumbler__buttons">
          <Button
            isLoading={isLoading}
            color={isLoading ? 'grey' : buttonColor()}
            text={buttonText()}
            onClick={() => {
              toggleAll()
              setLoading(true)

              setTimeout(function () {
                setLoading(false)
              }, 3000)
            }}
          />
          {currentStatus === 'mean' && (
            <Button
              isLoading={isLoading}
              color={isLoading ? 'grey' : 'red'}
              text={t('sidebar.stopAll')}
              onClick={() => {
                setAllOff()
                setLoading(true)

                setTimeout(function () {
                  setLoading(false)
                }, 3000)
              }}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default Tumbler
