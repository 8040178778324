import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import LangSwitcher from '../../ui/LangSwitcher'

import './style.scss'

const Footer = () => {
  const {t} = useTranslation()
  let [code, setCode] = useState('ru')

  useEffect(() => {
    setCode(t('get_current_language_code'))
  }, [t])

  return (
    <div className="footer">
      <div className="footer__wrapper">
        <div className="footer__copyright">© 2021 CVA Tec. Co. All rights reserved</div>
        <div className="footer__socials">
          <div className="social-list"></div>
        </div>
        <div className="footer__lang">
          <LangSwitcher code={code} />
        </div>
      </div>
    </div>
  )
}

export default Footer
