import React, {useEffect} from 'react'

import {ProfileToken} from '../../helpers/token'

import './style.scss'

const ConfirmationPage = ({history}) => {
  useEffect(() => {
    if (ProfileToken.get()) history.push('/')
  }, [history])

  return (
    <div className="login-page">
      <div className="login-page__wrapper">
        <div className="login-page__about">Account confirmed</div>
        <br></br>
        <div className="login-page__about">Now you can login to cabinet </div>
      </div>
    </div>
  )
}

export default ConfirmationPage
